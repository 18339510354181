import React from "react"

import BackgroundImage from "gatsby-background-image"

const BackgroundSection = ({ className, children, fluid, id }) => {
  return (
    <BackgroundImage Tag="section" className={className} id={id} fluid={fluid}>
      {children}
    </BackgroundImage>
  )
}

export default BackgroundSection
