import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import classes from "./Hours.module.scss"
import { today } from "../../../../utils/date"

// const today = getCurrentDay()

const Hours = () => {
  const {
    markdownRemark: {
      frontmatter: { hours },
    },
  } = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { hours: { friday: { ne: null } } }) {
        frontmatter {
          hours {
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            sunday
          }
        }
      }
    }
  `)

  const days = Object.keys(hours)
  const time = Object.values(hours)

  return (
    <div className={classes.Hours}>
      <h3>hours</h3>
      <table>
        <tbody>
          {days.map((day, index) => {
            return (
              <tr key={index} className={day === today ? classes.today : null}>
                <td>{day}</td>
                <td className={classes.storeHours}>{time[index]}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Hours
