const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
]

const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
]

export const d = new Date()

const getCurrentDay = () => {
  const today = days[d.getDay()]
  return today
}

const getCurrentMonth = () => {
  const month = months[d.getMonth()]
  return month
}

const getNextMonth = () => {
  let month = months[d.getMonth() + 1]
  if (d.getMonth() === 11) month = months[0]
  return month
}

export const today = getCurrentDay()
export const currentMonth = getCurrentMonth()
export const nextMonth = getNextMonth()
