import React from "react"
import loadable from "@loadable/component"

import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import Hero from "../components/Hero/Hero"

import { AppProvider } from "../context/AppContext"

const Navbar = loadable(() => import("../components/Navbar/Navbar"))
const MobileNav = loadable(() => import("../components/MobileNav/MobileNav"))
const Specials = loadable(() => import("../components/Specials/Specials"))
const StayConnected = loadable(() =>
  import("../components/StayConnected/StayConnected")
)
const Team = loadable(() => import("../components/Team/Team"))
const Footer = loadable(() => import("../components/Footer/Footer"))

const IndexPage = () => {
  return (
    <AppProvider>
      <Layout>
        <SEO title="Home" />
        <Navbar />
        <MobileNav />
        <main>
          {/* Hidden h1 for accessibility */}
          <h1 className="visuallyhidden">
            Peabody's Wine &amp; Beer Merchants
          </h1>
          <Hero />
          <Specials />
          <StayConnected />
          <Team />
          <Footer />
        </main>
      </Layout>
    </AppProvider>
  )
}

export default IndexPage
