import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import classes from "./Hero.module.scss"

import About from "./About/About"
import Contact from "./Contact/Contact"
import BackgroundSection from "../BackgroundSection/BackgroundSection"

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      mobile: file(relativePath: { eq: "backgrounds/hero_mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 542, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portrait: file(relativePath: { eq: "backgrounds/hero_test.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      landscape: file(relativePath: { eq: "backgrounds/hero_landscape.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 6000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const sources = [
    data.mobile.childImageSharp.fluid,
    {
      ...data.portrait.childImageSharp.fluid,
      media: `(min-width: 501px)`,
    },
    {
      ...data.landscape.childImageSharp.fluid,
      media: `(min-width: 1600px)`,
    },
  ]

  return (
    <BackgroundSection className={classes.Hero} fluid={sources} id="contact">
      <div className="container">
        <About />
        <Contact />
      </div>
    </BackgroundSection>
  )
}

export default Hero
