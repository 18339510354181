import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import classes from "./Contact.module.scss"
import Title from "../../Title/Title"
import Hours from "./Hours/Hours"

const Contact = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { contact: { address: { ne: null } } }) {
        frontmatter {
          contact {
            address
            city
            email
            phone
          }
        }
      }
    }
  `)

  const {
    markdownRemark: {
      frontmatter: {
        contact: { address, city, email, phone },
      },
    },
  } = data
  return (
    <div className={classes.Contact}>
      <Title>COME SEE US!</Title>

      <div>
        <h3>address</h3>
        <a href="https://www.google.com/maps/place/Peabody's+Wine+%26+Beer+Merchants/@36.204044,-81.674455,17z/data=!3m1!4b1!4m5!3m4!1s0x8850fa69070afb4b:0x1aeafcf08384b8ff!8m2!3d36.204044!4d-81.672261">
          <p>{address}</p>
          <p>{city}</p>
        </a>
      </div>

      <div>
        <h3>phone</h3>
        <p>
          <a href={`tel:${phone}`}>{phone}</a>
        </p>
        <p>Call ahead and curbside pickup are available.</p>
      </div>

      <div>
        <h3>email</h3>
        <p>
          <a
            href={`mailto:${email}`}
            target="_top"
            className={classes.EmailLarge}
          >
            {email}
          </a>
          {/* Shows when small to prevent text from pushing edge of window */}
          <a
            href={`mailto:${email}`}
            target="_top"
            className={classes.EmailSmall}
          >
            Send Us an Email!
          </a>
        </p>
      </div>

      <Hours />
    </div>
  )
}

export default Contact
