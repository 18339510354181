import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import sanitizeHtml from "sanitize-html"

import classes from "./About.module.scss"

import Logo from "../../Logo/Logo"

const About = () => {
  const {
    markdownRemark: { html },
  } = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { title: { eq: "About" } }) {
        html
      }
    }
  `)

  return (
    <div className={classes.About}>
      <div className={classes.Logo}>
        <Logo />
      </div>
      <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }}></div>
    </div>
  )
}

export default About
