import React from "react"
import classes from "./Title.module.scss"

const title = ({ children, color }) => {
  let titleColor = "var(--color-cream)"
  if (color === "red") titleColor = "var(--color-primary)"
  return (
    <h2 className={classes.Title} style={{ color: titleColor }}>
      {children}
    </h2>
  )
}

export default title
